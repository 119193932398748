//1.导入vue 和 vuerouter 的包
import Vue from 'vue'
import VueRouter from 'vue-router'

//2.调用vue.use() 函数，把 VueRouter 安装为 Vue 的插件
//vue.use()函数的作用，就是来安装插件的
Vue.use(VueRouter)

//3.公共路由
const constantRoutes = [{
	path: '/',
	name: 'home',
	component: () => import('@/views/home/index.vue'),
	children: []
},]

export default new VueRouter({
	mode: 'history', // 去掉url中的#
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})